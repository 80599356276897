/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by P   aul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        var previous_selector;
        var tech_guide_title = $('#tech-guide-title');

        //Change Tech Guide Titles based on selection.
        function filter_tech_guides(location_selected) {
          $('.tech-guides-navigation a').removeClass('active');
          $('.tech-guides-navigation a[data-select-location=' + location_selected + ']').addClass('active');
          $('.card[data-location~=' + location_selected + ']').removeClass('hidden');
          $('.card:not([data-location~=' + location_selected + '])').addClass('hidden');
          switch (location_selected) {
            case 'rbhs':
              tech_guide_title.html('RBHS Tech Guide');
              break;
            case 'new-brunswick':
              tech_guide_title.html('Rutgers University&ndash;New Brunswick Tech Guide');
              break;
            case 'camden':
              tech_guide_title.html('Rutgers University&ndash;Camden Tech Guide');
              break;
            case 'newark':
              tech_guide_title.html('Rutgers University&ndash;Newark Tech Guide');
              break;
          }
          $('.tech-guides-navigation .col').removeClass('col-6');
        }

        /*
            On DOM Load functions
         */
        $('document').ready(function () {
          //Close Divi Accordions by Default
          $('.et_pb_accordion .et_pb_toggle_open').addClass('et_pb_toggle_close').removeClass('et_pb_toggle_open');

          $('.et_pb_accordion .et_pb_toggle').click(function () {
            $this = $(this);
            setTimeout(function () {
              $this.closest('.et_pb_accordion').removeClass('et_pb_accordion_toggling');
            }, 700);
          });

          //Tech Guide Filtering by Hash Value
          urlParams = new URLSearchParams(window.location.search);
          myParam = urlParams.get('loc');


          if (myParam) {
            filter_tech_guides(myParam);
          } else if (window.location.hash) {
            filter_tech_guides(window.location.hash.substring(1));
          }

          $('.tech-guides-navigation a').on('click', function (e) {
            var location_selected = $(this).attr('data-select-location');
            filter_tech_guides(location_selected);
          });

          //Search Button
          $('#search-button a').click(function (e) {
            e.preventDefault();
            $(this).toggleClass("active");
            $('.header-search-form').toggleClass('closed');
            $("i", this).toggleClass('fas fa-search').toggleClass('far fa-times-circle');
            setTimeout(function () {
              $('input.orig').focus();
            }, 300);
          });

          //Mobile Menu toggle
          $('.mobile-menu').on('click', function () {
            $('.nav-primary,.mobile-overlay, body').toggleClass('is-active');
            $(this).attr('aria-expanded',function (i, attr) {
              return attr == 'true' ? 'false' : 'true'
            });
          });

          //Mobile Menu Overlay (When Menu is collapsed)
          $('.mobile-overlay').on('click', function () {
            $('.nav-primary,.mobile-overlay, body').removeClass('is-active');
          });

          //Add Focus Class Events for Secondary+ Nav Items
          $('.menu-item-has-children a').focus(function () {
            $(this).siblings('.sub-menu').addClass('focused');
            $(this).addClass('focused');
          }).blur(function () {
            $(this).siblings('.sub-menu').removeClass('focused');
            $(this).removeClass('focused');

          });

          $('.sub-menu a').focus(function () {
            $(this).parents('.sub-menu').addClass('focused');
            $(this).addClass('focused');
          }).blur(function () {
            $(this).parents('.sub-menu').removeClass('focused');
            $(this).removeClass('focused');
          });

          //Mobile/Tablet Open on Click event for Secondary+ Nav Items
          $('header .menu-item-has-children').each(function () {
            var title = $(this).children("a").text();
            var mobile_append =
              "<span class=\"mobile-expand\" aria-label=\"Open the " + title + " submenu\" tabindex='0'><i aria-hidden=\"false\" class=\"fas fa-plus\"></i></span>";
            $(this).prepend(mobile_append);
            $(".mobile-expand",this).attr('data-label', title);
          });
          console.log("V1");
          //Expand class for CSS

          function accessible_mobile_expand(mobile_expand) {
            mobile_expand.siblings('.sub-menu').toggleClass('is-expanded');
            var label =  mobile_expand.attr('data-label');
            console.log("click1");

            mobile_expand.toggleClass('is-expanded').attr('aria-expanded',function (i, attr) {
              return attr == 'true' ? 'false' : 'true';
            });

            if(mobile_expand.hasClass('is-expanded')) {
              mobile_expand.attr('aria-label', "Close the " + label + " submenu");
            } else {
              mobile_expand.attr('aria-label', "Open the " + label + " submenu");
            }
          }


          $('.mobile-expand').on('click', function () {
            var mobile_expand = $(this);
            accessible_mobile_expand(mobile_expand);
          });

          $('#menu-header-menu a[href="#"]').each(function(){
            $(this).attr('tabindex','-1');
          });

          //Tab focus out, close menu.
          $('.header-search-form').on('focusout',function() {
            $("a#search-toggle").click();

            if($('.mobile-menu').attr("aria-expanded") === "true") {

              $('.mobile-menu').click();
            }
          });

          $('#search-toggle').on('focusout', function(){
            if(!$(this).hasClass("active")) {
              $('.mobile-menu').click();
            }
          })

          $(".mobile-expand").on('keyup', function (e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
              var mobile_expand = $(this);
              accessible_mobile_expand(mobile_expand);
            }
          });


          //Owl Carousel setting for more than one slide.
          $('.banners.owl-carousel:not(.single-item)').owlCarousel({
            loop: true,
            items: 1,
            autoplay: true,
            autoplayTimeout: 10000
          });

          //Disable Slideshow if only a Single Item is in the Carousel
          $('.banners.owl-carousel.single-item').owlCarousel({
            loop: false,
            items: 1,
            autoplay: false,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false
          });
        });

        //Divi Accordion Collapse Fix
        $('.et_pb_toggle_title').click(function () {
          var $toggle = $(this).closest('.et_pb_toggle');
          if (!$toggle.hasClass('et_pb_accordion_toggling')) {
            var $accordion = $toggle.closest('.et_pb_accordion');
            if ($toggle.hasClass('et_pb_toggle_open')) {
              $accordion.addClass('et_pb_accordion_toggling');
              $toggle.find('.et_pb_toggle_content').slideToggle(700, function () {
                $toggle.removeClass('et_pb_toggle_open').addClass('et_pb_toggle_close');

              });
            }
            setTimeout(function () {
              $accordion.removeClass('et_pb_accordion_toggling');
            }, 750);
          }
        });
        $('.context-oarc .tribe-events-calendar-list a,.context-oarc .tribe-events-calendar-month__body a, .context-oarc .tribe-events-calendar-day a, .context-oarc .tribe-events-back a, .context-oarc .tribe-events-event-categories a, .context-oarc #tribe-events-footer a').each(function () {
          $(this).attr('href', $(this).attr('href') + '?context=oarc');
        });

        //View More button functionality for Knowledgebase Archives
        $('.article-count-badge, .view-more').on('click', function (e) {
          e.preventDefault();
          var topic = "." + $(this).attr('data-topic');
          if ($(topic).hasClass("expanded")) {
            $(topic + ' .categories a').each(function () {
              //console.log("Executing Un-Expanding");
              $(topic + ' .categories .visible').addClass('hidden').removeClass('visible');
              $(topic).removeClass("expanded");
            });
          } else {
            $(topic + ' .categories a').each(function () {
              //console.log("Executing Expanding");
              $(topic + ' .categories .hidden').addClass('visible').removeClass('hidden');
              $(topic).addClass("expanded");
            });
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
